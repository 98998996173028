import { makeStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {},

  cardContent: {
    padding: theme.spacing(4),
  },

  content: {
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridColumnGap: theme.spacing(5),
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "auto 1fr",
      gridTemplateAreas: ` 
        "header figure"
        "info   figure"
      `,

      "& $contentHeader": {
        gridArea: "header",
      },

      "& $contentInfo": {
        gridArea: "info",
      },

      "& $contentFigure": {
        gridArea: "figure",
      },
    },

    "& h2": {
      ...theme.typography.h2,

      [theme.breakpoints.down("sm")]: {
        "& strong": {
          height: 84,
          verticalAlign: "middle",
        },

        "& br": {
          display: "none",
        },
      },

      [theme.breakpoints.up("md")]: {
        lineHeight: "1.4em",
      },
    },

    "& p": {
      margin: "1.2em 0",

      "& a": {
        color: theme.palette.secondary.main,
        textDecoration: "underline",
      },
    },
  },

  contentHeader: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
    },
  },

  contentInfo: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },

  contentFigure: {
    margin: 0,
  },

  button: {
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      width: "50%",
      margin: "auto",
    },
  },

  videoWrapper: {
    height: 0,
    width: "100%",
    position: "relative",
    paddingTop: "56.25%",
  },

  video: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
});

export default makeStyles(styles);
